/** @jsx jsx */

import { Fragment } from 'react'
import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'

export const halo = {
  title: `Glossary`,
}

export const intro = {
  ...halo,
  route: routes.glossary,
  description: `Glossary of terms used throughout the site`,
  secondaryDescription: (
    <p>
      Certain terms used in the piece entitled “Carta, Inc. Auction case study: January 2021” available{` `}
      <UniversalLink to={routes.caseStudiesCarta} sx={{ color: `inherit` }}>
        here
      </UniversalLink>
      , may carry different meanings to how those terms might be used in other contexts, for example in the context of
      public markets, primary capital raisings, or initial public offerings. An explanation of such terms in this
      context may be found below.
    </p>
  ),
}

const items = [
  {
    slug: `liquidity`,
    term: `Liquidity`,
    definition: `refers to periodic auctions made available through the existence of CartaX for permissioned shareholders of certain restricted securities to sell those securities in the secondary market. Liquidity in this context does not mean that there is an open and continuous market akin to publicly traded securities.`,
  },
  {
    slug: `listed-on-cartax`,
    term: `Listed on CartaX`,
    definition: `refers to the status of certain Issuers who have taken the necessary steps to authorize their shares for trading on CartaX, including applying to do so, and passing the quantitative and qualitative due diligence reviews imposed by CCMX before being authorized to configure a transaction in their securities on CartaX. “Listed on CartaX” does not constitute an endorsement by CCMX of the investment-worthiness or otherwise of that issuer’s stock, and does not constitute an issuer’s status as having completed an initial public offering nor direct listing on a national securities exchange.`,
  },
  {
    slug: `investor-day:-company-presentations`,
    term: `Investor Day: Company Presentations`,
    definition: `refers to pre-recorded annual presentations by the issuer’s executive leadership team which are made available to potential investors in advance of the transaction on CartaX so that investors have full disclosure of company information before making their own investment decisions as to whether or not to participate in the transaction.`,
  },
  {
    slug: `investor-day:-live-q-and-a`,
    term: `Investor Day: Live Q&A`,
    definition: `refers to the question and answer portion of the Investor Day event facilitated by CCMX which allows prospective investors in a CartaX auction to ask questions of the issuer’s executive and senior management team.`,
  },
  {
    slug: `investor-1:1s`,
    term: `Investor 1:1s`,
    definition: `refers to scheduled meetings between the issuer’s executive leadership team and prospective investors on a 1-to-1 basis.`,
  },
  {
    slug: `transaction-launch`,
    term: `Transaction Launch`,
    definition: `refers to the commencement of a transaction on CartaX and the start of the order entry period.`,
  },
  {
    slug: `transaction-execution-and-settlement`,
    term: `Transaction Execution and Settlement`,
    definition: `refers to the conclusion of an auction on CartaX at which point the clearing price is calculated and marketable orders are executed, and to the related processes employed to affect the changes in beneficial ownership of securities and movement of cash necessary to settle a transaction.`,
  },
]

export const content = (
  <dl sx={{ maxWidth: `70ch`, my: 4 }}>
    {items.map(({ slug, term, definition }) => {
      return (
        <Fragment key={slug}>
          <dt id={slug} sx={{ pt: 4 }}>
            <a
              href={`#${slug}`}
              sx={{
                fontWeight: `600`,
                fontSize: 3,
                textDecoration: `none`,
                color: `inherit`,

                [`:hover, :focus`]: {
                  textDecoration: `underline`,
                },
              }}
            >
              {term}
            </a>
          </dt>
          <dd sx={{ mt: 1, ml: 0 }}>
            <i>{term}</i> {definition}
          </dd>
        </Fragment>
      )
    })}
  </dl>
)
