/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import { content, halo, intro } from '../content/glossary'

const Glossary: FC = () => {
  return (
    <Layout>
      <Halo {...halo} />
      <Intro {...intro} />
      <section sx={{ bg: `white_slate8` }}>
        <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4 }}>{content}</div>
      </section>
    </Layout>
  )
}

export default Glossary
